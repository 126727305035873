import React, { Component } from 'react';
import {Checkbox, Radio} from 'semantic-ui-react';
import { connect } from 'react-redux';

import Tip from '../../../01_atoms/Tip';
import Price from '../../../01_atoms/Price';
import BlockContainer from '../../BlockContainer';
import { deleteBlockValue, updateBlockValue } from '../../../../store/actions';
import { translate } from '../../../../utils/translations';
import getBlockImage from "../../../../utils/getBlockImage";
import getInitializedBlockObj from "../../../../utils/getInitializedBlockObj";
import getCalculatedBlock from "../../../../utils/getCalculatedBlock";

class ArchitecturalProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: getBlockImage(this.props.block),
      lastChecked: undefined,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.handleSubOptionChange = this.handleSubOptionChange.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;

    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  handleSubOptionChange(e, { value }) {
    const { block, dispatch, blockValue } = this.props;

    const newBlockValue = {...blockValue};
    newBlockValue[value].checked = !newBlockValue[value].checked;

    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  }

  handleChange = (e, { value }) => {
    const { block, dispatch } = this.props;
    const newBlockValue = {};
    newBlockValue.active_index = value;

    const option = block.values.options.find(option=>(option['machine_name'] === value));
    if (option.hasOwnProperty('sub_options')) {
      option['sub_options'].forEach(subOption => {
        const id = subOption['machine_name'];
        newBlockValue[id] = {};
        newBlockValue[id].checked = !!subOption.checked;
      })
    }

    dispatch(updateBlockValue(block.machine_name, newBlockValue));

    this.setState({lastChecked: value})
  };

  onMouseOver(e){
    const { block } = this.props;
    const optionIndex = e.target.previousSibling.value;
    const optionImage = getBlockImage(block, optionIndex);
    if (typeof optionImage !== "undefined") {
      this.setState({currentImage: optionImage});
    } else {
      const blockImage = getBlockImage(block);
      this.setState({currentImage: blockImage});
    }
  }

  onMouseOut(){
    const { block } = this.props;
    const lastChecked = this.state.lastChecked;
    if (typeof lastChecked !== "undefined") {
      const optionImage = getBlockImage(block, lastChecked);
      if (typeof optionImage !== "undefined"){
        this.setState({currentImage: optionImage});
      } else {
        const blockImage = getBlockImage(block);
        this.setState({currentImage: blockImage});
      }
    } else {
      const blockImage = getBlockImage(block);
      this.setState({currentImage: blockImage});
    }
  }

  render() {
    const { block, language, blockPriceValues } = this.props;

    const { options } = block.values;
    return (
      <BlockContainer
        image={this.state.currentImage}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        {options.map((option) => {
          const id = option['machine_name'];
          const priceValue = blockPriceValues[id].price;
          const checked = blockPriceValues.active_index === id;
          const hasSubOptions = option.hasOwnProperty('sub_options');
          const activeClass = checked ? 'active' : '';

          const optionForRender = (
            <div className={`option-container ${activeClass}`} key={id}>
              <div className='option'>
                <Radio
                  label={translate(option.label, language)}
                  name={block['machine_name']}
                  onChange={this.handleChange}
                  onMouseOver = {this.onMouseOver}
                  onMouseOut = {this.onMouseOut}
                  value={id}
                  checked={blockPriceValues.active_index === id}
                  disabled={blockPriceValues[id].price === ''}
                />

                {option.description && Tip(option.description)}
              </div>

              <div className='option-price'>
                { Price(priceValue.toString()) }
              </div>
            </div>
          );

          let subOptionsForRender = [];

          if (checked && hasSubOptions) {
            const subOptions = option['sub_options'];
            subOptions.forEach(subOption => {
              const id = subOption['machine_name'];
              const priceValue = blockPriceValues[id].price;
              const checked = !!blockPriceValues[id].checked;
              const activeClass = checked ? 'active' : '';

              subOptionsForRender.push(
                <div className={`option-container ${activeClass}`} key={id}>
                  <div className='option'>
                    <Checkbox
                      label={translate(subOption.label, language)}
                      name={block['machine_name']}
                      onChange={this.handleSubOptionChange}
                      onMouseOver={this.onMouseOver}
                      onMouseOut={this.onMouseOut}
                      value={id}
                      checked={checked}
                      disabled={blockPriceValues[id].price === ''}
                    />

                    {subOption.description && Tip(subOption.description)}
                  </div>

                  <div className='option-price'>
                    { Price(priceValue.toString()) }
                  </div>
                </div>
              );
            })
          }

          return (
            <>
              {optionForRender}
              {subOptionsForRender}
            </>
          );
        })}
      </BlockContainer>
    );
  }
}

const mapStateToProps = (state, { block }) => {
  const { blocks, language } = state;

  const blockValue = getInitializedBlockObj(block, blocks);

  const blockPriceValues = getCalculatedBlock(block, blockValue, state);

  return {
    blockValue,
    language,
    blockPriceValues,
  };
};

export default connect(mapStateToProps)(ArchitecturalProject);
