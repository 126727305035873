import React, { Component } from 'react';
import {Button} from 'semantic-ui-react';
import { connect } from 'react-redux';
import Price from '../../../01_atoms/Price';
import BlockContainer from '../../BlockContainer';
import { deleteBlockValue, updateBlockValue } from '../../../../store/actions';
import TranslatedText from '../../../01_atoms/TranslatedText';
import Tip from '../../../01_atoms/Tip';
import { stairsPriceCalculation } from '../../../../utils/blocksPriceCalculation';
import {initialStairsBlock} from "../../../../utils/initialBlock";
import {inputFilter} from "../../../../utils/inputFilter";
import Input from "../../../01_atoms/Input";
import {translate} from "../../../../utils/translations";

class Stairs extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    const { blockPriceValues } = this.props;

    Object.keys(blockPriceValues).forEach(key =>{
      let width = Number(blockPriceValues[key].width);
      let steps = Number(blockPriceValues[key].steps);
      width = width === 0 ? '' : width;
      steps = steps === 0 ? '' : steps;
      this.state[key] = {
        width: width,
        steps: steps
      };
    });

    this.handleChange = this.handleChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;

    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  handleChange(e) {
    const { value, name } = e.target;
    const { block, dispatch, blockValue } = this.props;

    let inputValue = inputFilter(value);

    const stairNumber = /\d+/.exec(name)[0];
    const stairOption = /width|steps/.exec(name)[0];

    const previousState = this.state[stairNumber];
    this.setState({[stairNumber]:{
      ...previousState,
        [stairOption]: inputValue
    }});

    inputValue = inputValue === '' ? 0 : inputValue;

    const newBlockValue = { ...blockValue };

    newBlockValue[stairNumber][stairOption] = inputValue;

    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  }

  handleAddClick() {
    const { dispatch, blockValue, block } = this.props;

    const optionsId = Object.keys(blockValue);
    const lastId = optionsId[optionsId.length - 1];

    const newId = typeof lastId === "undefined" ? 0 : Number(lastId) + 1;

    const newOption = {width: '', steps: ''};

    this.setState({[newId]: newOption});

    let newBlockValue = {...blockValue};

    newBlockValue[newId] = newOption;

    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  }

  handleRemoveClick(id) {
    const { dispatch, blockValue, block } = this.props;

    let newBlockValue = {...blockValue};
    delete newBlockValue[id];

    const state = this.state;
    delete state[id];
    this.setState(state);

    const optionsCount = Object.keys(blockValue).length;

    if (optionsCount === 1) {
      newBlockValue[0] = {width: '', steps: ''};
      this.setState(newBlockValue);
    }

    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  }

  render() {
    const { block, blockPriceValues, language } = this.props;

    const stairsArr = [];
    Object.keys(blockPriceValues).forEach( (id) => {
      const item = blockPriceValues[id];
      const stair = block.values.options[0];
      const { price } = item;
      const activeClass = Number(price) === 0 ? '' : 'active';
      const { width, steps} = this.state[id];

      stairsArr.push(
        <div className={`option-container ${activeClass}`} key={id}>
          <div className='option'
               style={{
                 display: 'flex',
                 alignItems: 'center',
                 flexWrap: 'wrap',
               }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="15"
              height="15"
              onClick={() => {this.handleRemoveClick(id)}}
              className='option-delete-icon'
              style={{
                marginRight: '6px',
                marginLeft: '0'
              }}
            >
              <path d="M4.99 3.99a1 1 0 00-.697 1.717L10.586 12l-6.293 6.293a1 1 0 101.414 1.414L12 13.414l6.293 6.293a1 1 0 101.414-1.414L13.414 12l6.293-6.293a1 1 0 00-.727-1.717 1 1 0 00-.687.303L12 10.586 5.707 4.293a1 1 0 00-.717-.303z"/>
            </svg>
            {`${translate(stair.label, language)}-${+id + 1}: ${translate('Width', language)}`}
            <Input
              style={{ marginRight: '10px' }}
              label={<TranslatedText text="l.m" />}
              onChange={this.handleChange}
              name={`${id}_width`}
              placeholder="0"
              labelPosition="right"
              value={width}
            />
            x <TranslatedText text="Amount of steps" />
            <Input
              style={{ marginLeft: '10px' }}
              onChange={this.handleChange}
              placeholder="0"
              name={`${id}_steps `}
              value={steps}
            />
            {stair.description && Tip(stair.description)}
          </div>
          <div className='option-price'>
            {Price(price.toString())}
          </div>
        </div>,
      );
    });

    return (
      <BlockContainer
        image={block.image}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        {stairsArr}

        <Button
          style={{display:'flex', alignItems:'center', marginTop: '9px'}}
          onClick={this.handleAddClick}
          basic
          compact
        >
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"
               style={{
                 overflow: 'visible',
                 marginRight: '5px'
               }}
          >
            <g fill="none" fillRule="evenodd">
              <circle stroke="#05B5AE" strokeWidth="2" fill="#FFF" cx="12" cy="12" r="9"/>
              <path fill="#05B5AE" d="M11 7h2v10h-2z"/>
              <path fill="#05B5AE" d="M17 11v2H7v-2z"/>
            </g>
          </svg>
          <TranslatedText text='Add stair'/>
        </Button>
      </BlockContainer>
    );
  }
}

const mapStateToProps = ({ blocks, houseId, region, language }, { block }) => {

  const blockValue = initialStairsBlock(block, blocks);

  const blockPriceValues = stairsPriceCalculation(block, blockValue, { houseId, region });
  delete blockPriceValues['totalPrice'];

  return {
    language,
    blockValue,
    blockPriceValues,
  };
};

export default connect(mapStateToProps)(Stairs);
