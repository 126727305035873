import React, { Component } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Tip from '../../../01_atoms/Tip';
import Price from '../../../01_atoms/Price';
import BlockContainer from '../../BlockContainer';
import { deleteBlockValue, updateAreaValue, updateBlockValue } from '../../../../store/actions';
import { translate } from '../../../../utils/translations';
import { porchPriceCalculation } from '../../../../utils/blocksPriceCalculation';
import getBlockImage from "../../../../utils/getBlockImage";
import {initialPorchBlock} from "../../../../utils/initialBlock";
import divideNumber from "../../../../utils/divideNumber";
import getCurrencySign from "../../../../utils/getCurrencySign";
import Input from "../../../01_atoms/Input";
import {inputFilter} from "../../../../utils/inputFilter";

class Porch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastChecked: undefined,
      currentImage: props.block.image,
    };

    this.checkboxHandleChange = this.checkboxHandleChange.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;

    const activeIndexArr = [];
    Object.keys(blockValue).forEach((index) => {
      if (blockValue[index].checked) {
        activeIndexArr.push(index);
      }
    });

    dispatch(updateBlockValue(block.machine_name, blockValue));
    dispatch(updateAreaValue(block.machine_name, activeIndexArr));
  }

  checkboxHandleChange(e, { value }) {
    const { dispatch, blockValue, block } = this.props;
    const newBlockValue = { ...blockValue };
    newBlockValue[value].checked = !newBlockValue[value].checked;

    const activeIndexArr = [];
    Object.keys(newBlockValue).forEach((index) => {
      if (newBlockValue[index].checked) {
        activeIndexArr.push(index);
      }
    });

    dispatch(updateBlockValue(block.machine_name, newBlockValue));
    dispatch(updateAreaValue(block.machine_name, activeIndexArr));

    if (newBlockValue[value].checked) {
      this.setState({lastChecked: value})
    } else {
      this.setState({lastChecked: undefined})
    }
  }

  onMouseOver(e){
    const { block } = this.props;
    const optionIndex = e.target.previousSibling.value;
    const optionImage = getBlockImage(block, optionIndex);
    if (typeof optionImage !== "undefined") {
      this.setState({currentImage: optionImage});
    } else {
      const blockImage = getBlockImage(block);
      this.setState({currentImage: blockImage});
    }
  }

  onMouseOut(){
    const { block } = this.props;
    const lastChecked = this.state.lastChecked;
    if (typeof lastChecked !== "undefined") {
      const optionImage = getBlockImage(block, lastChecked);
      if (typeof optionImage !== "undefined"){
        this.setState({currentImage: optionImage});
      } else {
        const blockImage = getBlockImage(block);
        this.setState({currentImage: blockImage});
      }
    } else {
      const blockImage = getBlockImage(block);
      this.setState({currentImage: blockImage});
    }
  }

  handleChange(e) {
    const { value, name } = e.target;
    const { block, dispatch, blockValue } = this.props;

    let inputValue = inputFilter(value);
    inputValue = inputValue === '' ? 0 : inputValue;
    const newBlockValue = { ...blockValue };
    newBlockValue[name].value = inputValue;

    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  }

  render() {
    const { block, language, blockPriceValues } = this.props;
    const { options } = block.values;

    return (
      <BlockContainer
        image={this.state.currentImage}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        {options.map((option, index) => {
          const price = blockPriceValues[index].price.toString();
          const { unitPrice } = blockPriceValues[index];
          const activeClass = blockPriceValues[index].checked ? 'active' : '';
          const inputValue = blockPriceValues[index].value;

          return (
            <div className={`option-container ${activeClass}`} key={index}>
              <div className='option'>
                <Checkbox
                  label={translate(option.label, language)}
                  name={block.machine_name}
                  onChange={this.checkboxHandleChange}
                  onMouseOver = {this.onMouseOver}
                  onMouseOut = {this.onMouseOut}
                  value={index}
                  checked={blockPriceValues[index].checked}
                />

                <Input
                  label={`x ${divideNumber(unitPrice)} ${getCurrencySign()}`}
                  onChange={this.handleChange}
                  name={index}
                  labelPosition="right"
                  placeholder="0"
                  value={inputValue}
                />

                {option.description && Tip(option.description)}

              </div>

              <div className='option-price'>
                { Price(price) }
              </div>
            </div>
          );
        })}
      </BlockContainer>
    );
  }
}

const mapStateToProps = ({ blocks, houseId, region, language }, { block }) => {

  const blockValue = initialPorchBlock(block, blocks);

  const blockPriceValues = porchPriceCalculation(block, blockValue, { region, houseId });

  return {
    blockValue,
    language,
    blockPriceValues,
  };
};

export default connect(mapStateToProps)(Porch);
