import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import TranslatedText from '../TranslatedText';

export default function Tip(value, imageSource) {
  const text = <TranslatedText text={value} />;
  return (
    <Popup
      trigger={<Icon style={{ marginLeft: '10px' }} name="info circle" />}
    >
      <Popup.Content>
        <TranslatedText text={value} />
        {imageSource && <img src={imageSource} alt={text} />}
      </Popup.Content>
    </Popup>
  );
}
