import React, { Component } from 'react';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Tip from '../../../01_atoms/Tip';
import Price from '../../../01_atoms/Price';
import BlockContainer from '../../BlockContainer';
import { deleteBlockValue, updateBlockValue } from '../../../../store/actions';
import { getFoundationValues } from '../../../../utils/getFoundationValues';
import TranslatedText from '../../../01_atoms/TranslatedText';
import { foundationPriceCalculation } from '../../../../utils/blocksPriceCalculation';
import { translate } from '../../../../utils/translations';
import {initialFoundationRentalBlock} from "../../../../utils/initialBlock";
import {inputFilter} from "../../../../utils/inputFilter";
import Input from "../../../01_atoms/Input";
import getOfferType from "../../../../utils/getOfferType";

class Foundation extends Component {
  constructor(props) {
    super(props);

    this.checkboxHandleChange = this.checkboxHandleChange.bind(this);
    this.inputHandleChange = this.inputHandleChange.bind(this);
    this.selectHandleChange = this.selectHandleChange.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;
    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  checkboxHandleChange(e, { value }) {
    const { dispatch, blockValue, block } = this.props;
    blockValue[value].checked = !blockValue[value].checked;
    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  inputHandleChange(e) {
    const { name, value } = e.target;
    const { dispatch, blockValue, block } = this.props;

    let inputValue = inputFilter(value);

    inputValue = inputValue === '' ? 0 : inputValue;
    blockValue[name].value = inputValue;
    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  selectHandleChange(e, { value }) {
    const { block, dispatch, blockValue } = this.props;
    blockValue[0].value = parseFloat(value);
    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  render() {
    const { block, houseId, region, DDP_city, blockPriceValues, language, blockValue } = this.props;
    const { options } = block.values;

    return (
      <BlockContainer
        image={block.image}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        {options.map((option) => {
          const machineName = option['machine_name'];

          if (getOfferType() === 'retail') {
            switch (machineName) {
              case '2':
              case '4':
              case '6':
                return;
            }
          }

          let { description } = option;
          let label = translate(option.label, language);
          const { price } = blockPriceValues[machineName];
          const activeClass = blockPriceValues[machineName].checked ? 'active' : '';
          switch (machineName) {
            case '2':
            case '3':
            case '4':
            case '5':
              label += ` ${translate(DDP_city, language)}`;
              break;
          }
          let itemForRender = (
            <>
              <div className='option'>
                <Checkbox
                  label={label}
                  name={block.machine_name}
                  onChange={this.checkboxHandleChange}
                  value={machineName}
                  checked={blockPriceValues[machineName].checked}
                />
                {description && Tip(description)}
              </div>
              <div className='option-price'>
                { Price(price.toString()) }
              </div>
            </>
          );

          switch (machineName) {
            case '0':
              const foundationList = option.selections.map((select, index) => (
                {
                  key: index,
                  price: getFoundationValues(select.value, region, houseId).price,
                  value: index,
                  text: <TranslatedText text={select.label} />,
                }
              ));
              description = option.selections[blockPriceValues[machineName].value].description;
              itemForRender = (
                <>
                  <div className='option'>
                    <TranslatedText text={label} />
                    <Dropdown
                      selection
                      options={foundationList}
                      defaultValue={blockPriceValues[machineName].value}
                      onChange={this.selectHandleChange}
                      style={{ marginLeft: '10px' }}
                    />
                    {description && Tip(description)}
                  </div>

                  <div className='option-price'>
                    { Price(price.toString()) }
                  </div>
                </>
              );
              break;

            case '7':
              itemForRender = (
                <>
                  <div className='option'>
                    <TranslatedText text={label} />
                    {description && Tip(description)}
                  </div>
                  <div className='option-price'>
                    { Price(price.toString()) }
                  </div>
                </>
              );
              break;

            case '3':
            case '5':
              let inputValue = blockValue[machineName].value;
              inputValue = +inputValue === 0 ? '' : inputValue;

              itemForRender = (
                <>
                  <div className='option'>
                    <Checkbox
                      label={label}
                      onChange={this.checkboxHandleChange}
                      value={machineName}
                      checked={blockPriceValues[machineName].checked}
                    />
                    {description && Tip(description)}
                    <Input
                      label={`${translate('km', language)}`}
                      onChange={this.inputHandleChange}
                      name={machineName}
                      labelPosition="right"
                      type="text"
                      disabled={!blockPriceValues[machineName].checked}
                      value={inputValue}
                      placeholder={0}
                    />
                  </div>
                  <div className='option-price'>
                    { Price(price.toString()) }
                  </div>
                </>
              );
              break;
          }

          return (
            <div className={`option-container ${activeClass}`} key={machineName}>
              {itemForRender}
            </div>
          );
        })}
      </BlockContainer>
    );
  }
}


const mapStateToProps = ({
  blocks, houseId, region, area, DDP_distance, DDP_city, language,}, { block }) => {

  const blockValue = initialFoundationRentalBlock(block, blocks);

  const blockPriceValues = foundationPriceCalculation(block, blockValue, { region, houseId, area, DDP_distance, blocks });

  return {
    DDP_city,
    houseId,
    region,
    blockValue,
    blockPriceValues,
    language,
  };
};

export default connect(mapStateToProps)(Foundation);
