import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import RegionSelection from '../../02_molecules/RegionSelection';
import './_styles.scss';
import getData from "../../../utils/getData";
import TranslatedText from "../../01_atoms/TranslatedText";

class Header extends Component {
  render() {
    const data = getData();
    const { offer_type, logo_src, logo_link } = data.configuration;

    return (
      <div className="site-header">
        <div className='header-logo'>
          <a
            href={ logo_link }
            target='_blank'
          >
            <Image src={ logo_src } className='logo'/>
          </a>
          <span className='calculator-type'><TranslatedText text={offer_type}/></span>
        </div>
        <div style={{ height: '30px' }} />
        <RegionSelection />
      </div>
    );
  }
}

export default Header;
