import React, {Component} from 'react';
import {connect} from 'react-redux';
import Tip from '../../../01_atoms/Tip';
import BlockContainer from '../../BlockContainer';
import TranslatedText from '../../../01_atoms/TranslatedText';
import {inputFilter} from "../../../../utils/inputFilter";
import Input from "../../../01_atoms/Input";
import dataOverride from "../../../../data/data.override";
import {dataUpdate} from "../../../../app/DataSource";
import getData from "../../../../utils/getData";
import {updateStore} from "../../../../store/actions";

class JustInput extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { dispatch } = this.props;
    const { value } = e.target;
    let inputValue = inputFilter(value);
    inputValue = inputValue === '' ? 0 : inputValue;

    if (!dataOverride.hasOwnProperty('configuration')) {
      dataOverride['configuration'] = {}
    }
    dataOverride['configuration']['markup_percentage'] = inputValue;

    dataUpdate();
    dispatch(updateStore());
  }

  render() {
    const { block } = this.props;
    const { options } = block.values;

    return (
      <BlockContainer
        image={block.image}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        {options.map((option) => {
          const machineName = option['machine_name'];
          const data = getData();
          const { markup_percentage } = data['configuration'];

          return (
            <div className={`option-container`} key={option.label}>
              <div className='option'>
                <TranslatedText text={option.label} />
                <Input
                  onChange={this.handleChange}
                  name={machineName}
                  placeholder={markup_percentage}
                  value={markup_percentage ? markup_percentage : ''}
                />

                {option.description && Tip(option.description)}

              </div>

              <div className='option-price'>
              </div>
            </div>
          );
        })}
      </BlockContainer>
    );
  }
}

const mapStateToProps = (state, { block }) => {

  return {
    state,
    block
  };
};

export default connect(mapStateToProps)(JustInput);
