import React, { Component } from 'react';
import { Radio } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Tip from '../../../01_atoms/Tip';
import Price from '../../../01_atoms/Price';
import BlockContainer from '../../BlockContainer';
import { deleteBlockValue, updateBlockValue } from '../../../../store/actions';
import { translate } from '../../../../utils/translations';
import { radioPriceCalculation } from '../../../../utils/blocksPriceCalculation';
import getBlockImage from "../../../../utils/getBlockImage";
import {initialRadioButtonsBlock} from "../../../../utils/initialBlock";

class RadioButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: getBlockImage(this.props.block),
      lastChecked: undefined,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;

    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  handleChange = (e, { value }) => {
    const { block, dispatch, blockValue } = this.props;
    const newBlockValue = { ...blockValue };
    newBlockValue.active_index = value;
    dispatch(updateBlockValue(block.machine_name, newBlockValue));

    this.setState({lastChecked: value})
  };

  onMouseOver(e){
    const { block } = this.props;
    const optionIndex = e.target.previousSibling.value;
    const optionImage = getBlockImage(block, optionIndex);
    if (typeof optionImage !== "undefined") {
      this.setState({currentImage: optionImage});
    } else {
      const blockImage = getBlockImage(block);
      this.setState({currentImage: blockImage});
    }
  }

  onMouseOut(){
    const { block } = this.props;
    const lastChecked = this.state.lastChecked;
    if (typeof lastChecked !== "undefined") {
      const optionImage = getBlockImage(block, lastChecked);
      if (typeof optionImage !== "undefined"){
        this.setState({currentImage: optionImage});
      } else {
        const blockImage = getBlockImage(block);
        this.setState({currentImage: blockImage});
      }
    } else {
      const blockImage = getBlockImage(block);
      this.setState({currentImage: blockImage});
    }
  }

  render() {
    const { block, language, blockPriceValues } = this.props;

    const { options } = block.values;
    return (
      <BlockContainer
        image={this.state.currentImage}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        {options.map((option, index) => {
          const priceValue = blockPriceValues[index].price;
          const activeClass = blockPriceValues.active_index === index ? 'active' : '';
          return (
            <div className={`option-container ${activeClass}`} key={index}>
              <div className='option'>
                <Radio
                  label={translate(option.label, language)}
                  name={block.machine_name}
                  onChange={this.handleChange}
                  onMouseOver = {this.onMouseOver}
                  onMouseOut = {this.onMouseOut}
                  value={index}
                  checked={blockPriceValues.active_index === index}
                  disabled={blockPriceValues[index].price === ''}
                />

                {option.description && Tip(option.description)}

              </div>

              <div className='option-price'>
                { Price(priceValue.toString()) }
              </div>

            </div>
          );
        })}
      </BlockContainer>
    );
  }
}

const mapStateToProps = ({ blocks, houseId, region, language }, { block }) => {

  const blockValue = initialRadioButtonsBlock(block, blocks);

  const blockPriceValues = radioPriceCalculation(block, blockValue, { region, houseId });

  return {
    blockValue,
    language,
    blockPriceValues,
  };
};

export default connect(mapStateToProps)(RadioButtons);
