import React, { Component } from 'react';
import {Checkbox, Radio} from 'semantic-ui-react';
import { connect } from 'react-redux';

import Tip from '../../../01_atoms/Tip';
import Price from '../../../01_atoms/Price';
import BlockContainer from '../../BlockContainer';
import { deleteBlockValue, updateBlockValue } from '../../../../store/actions';
import { translate } from '../../../../utils/translations';
import getBlockImage from "../../../../utils/getBlockImage";
import getInitializedBlockObj from "../../../../utils/getInitializedBlockObj";
import getCalculatedBlock from "../../../../utils/getCalculatedBlock";

class RadioCheckboxes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: getBlockImage(this.props.block),
      lastChecked: undefined,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;
    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  handleChange(e, { value }) {
    const { block, dispatch, blockValue } = this.props;
    const newBlockValue = { ...blockValue };

    const option = block.values.options.find(option => (option['machine_name'] === value));
    const optionType = option.type;

    if (optionType === 'radio') {
      newBlockValue.active_index = value;
      this.setState({lastChecked: value})
    } else {
      newBlockValue[value].checked = !newBlockValue[value].checked;
      if (newBlockValue[value].checked) {
        this.setState({lastChecked: value})
      } else {
        this.setState({lastChecked: undefined})
      }
    }

    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  };

  onMouseOver(e){
    const { block } = this.props;
    const optionIndex = e.target.previousSibling.value;
    const optionImage = getBlockImage(block, optionIndex);
    if (typeof optionImage !== "undefined") {
      this.setState({currentImage: optionImage});
    } else {
      const blockImage = getBlockImage(block);
      this.setState({currentImage: blockImage});
    }
  }

  onMouseOut(){
    const { block } = this.props;
    const lastChecked = this.state.lastChecked;
    if (typeof lastChecked !== "undefined") {
      const optionImage = getBlockImage(block, lastChecked);
      if (typeof optionImage !== "undefined"){
        this.setState({currentImage: optionImage});
      } else {
        const blockImage = getBlockImage(block);
        this.setState({currentImage: blockImage});
      }
    } else {
      const blockImage = getBlockImage(block);
      this.setState({currentImage: blockImage});
    }
  }

  render() {
    const { block, language, blockPriceValues } = this.props;

    const { options } = block.values;
    return (
      <BlockContainer
        image={this.state.currentImage}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        {options.map((option) => {
          const id = option['machine_name'];
          const priceValue = blockPriceValues[id].price;
          let activeClass;

          let itemForRender;

          switch (option.type) {
            case 'checkbox': {
              activeClass = blockPriceValues[id].checked ? 'active' : '';
              itemForRender = (
                <Checkbox
                  label={translate(option.label, language)}
                  name={block['machine_name']}
                  onChange={this.handleChange}
                  onMouseOver={this.onMouseOver}
                  onMouseOut={this.onMouseOut}
                  value={id}
                  checked={blockPriceValues[id].checked}
                  disabled={blockPriceValues[id].price === ''}
                />
              );
              break;
            }

            case 'radio': {
              activeClass = blockPriceValues.active_index === id ? 'active' : '';
              itemForRender = (
                <Radio
                  label={translate(option.label, language)}
                  name={block['machine_name']}
                  onChange={this.handleChange}
                  onMouseOver = {this.onMouseOver}
                  onMouseOut = {this.onMouseOut}
                  value={id}
                  checked={blockPriceValues.active_index === id}
                  disabled={blockPriceValues[id].price === ''}
                />
              );
              break;
            }
          }
          return (
            <div className={`option-container ${activeClass}`} key={id}>
              <div className='option'>
                {itemForRender}
                {option.description && Tip(option.description)}
              </div>

              <div className='option-price'>
                { Price(priceValue.toString()) }
              </div>

            </div>
          );
        })}
      </BlockContainer>
    );
  }
}

const mapStateToProps = ({ blocks, houseId, region, language }, { block }) => {

  const blockValue = getInitializedBlockObj(block, blocks);

  const blockPriceValues = getCalculatedBlock(block, blockValue, { region, houseId });

  return {
    blockValue,
    language,
    blockPriceValues,
  };
};

export default connect(mapStateToProps)(RadioCheckboxes);
