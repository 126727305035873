import {translate} from "./translations";
import getOptionById from "./getOptionById";

export function radioPDFObj (blockData, calculatedBlock, language) {
  const resultObject = {};

  const activeIndex = calculatedBlock.active_index;
  const options = blockData.values.options;
  const activeOption = options[activeIndex];

  const price = calculatedBlock[activeIndex].price;
  const optionName = translate(activeOption.label, language);

  resultObject[optionName] = price;

  return resultObject;
}
export function checkboxesPDFObj (blockData, calculatedBlock, language) {
  delete calculatedBlock['totalPrice'];
  const resultObject = {};

  Object.keys(calculatedBlock).forEach((id) => {
    if (calculatedBlock[id].checked) {
      const option = getOptionById(blockData, id);
      const price = calculatedBlock[id].price;
      const value = 'value' in calculatedBlock[id] ?
        `: ${calculatedBlock[id].value}` :
        '';
      const optionName = `${translate(option.label, language)} ${value}`;

      resultObject[optionName] = price;
    }
  });

  return resultObject;
}
export function inputPDFObj (blockData, calculatedBlock, language) {
  delete calculatedBlock['totalPrice'];
  const resultObject = {};

  Object.keys(calculatedBlock).forEach((id) => {
    const option = blockData.values.options[id];
    const value = calculatedBlock[id].value;
    const price = calculatedBlock[id].price;
    const optionName = `${translate(option.label, language)}: ${value}`;

    resultObject[optionName] = price;
  });

  return resultObject;
}
export function customPDFObj (blockData, calculatedBlock, language) {
  delete calculatedBlock['totalPrice'];
  const resultObject = {};

  Object.keys(calculatedBlock).forEach(id => {
    const option = calculatedBlock[id];
    if (option.text !== '' || option.price !== 0) {
      const price = option.price;
      const optionName = translate(option.text, language);

      resultObject[optionName] = price;
    }
  });

  return resultObject;
}
export function stairsPDFObj (blockData, calculatedBlock, language) {
  delete calculatedBlock['totalPrice'];
  const resultObject = {};

  const option = blockData.values.options[0];
  Object.keys(calculatedBlock).forEach((id) => {
    const price = calculatedBlock[id].price;
    const { width, steps } = calculatedBlock[id];
    let optionName = `${translate(option.label, language)} ${+id + 1}. `;
    optionName += ` ${translate('Width', language)} : ${width}, `
      + ` ${translate('Steps', language)} : ${steps}`;

    resultObject[optionName] = price;
  });

  return resultObject;
}
export function radioCheckboxesPDFObj (blockData, calculatedBlock, language) {
  const resultObject = {};
  const activeId = calculatedBlock.active_index;

  delete calculatedBlock['totalPrice'];
  delete calculatedBlock.active_index;

  Object.keys(calculatedBlock).forEach((id) => {
    const option = calculatedBlock[id];
    if (option.checked || id === activeId) {
      const dataOptions = blockData.values.options;
      let optionData = dataOptions.find(item => (item['machine_name'] === id));
      if (typeof optionData === "undefined") {
        dataOptions.forEach(option => {
          if (option.hasOwnProperty('sub_options')) {
            const foundOption = option['sub_options'].find(option => (option['machine_name'] === id));
            if (typeof foundOption !== "undefined"){
              optionData = foundOption;
            }
          }
        })
      }

      const price = option.price;
      const optionName = translate(optionData.label, language);

      resultObject[optionName] = price;
    }
  });

  return resultObject;
}
export function verandaPDFObj (blockData, calculatedBlock, language) {
  const resultObject = {};
  const activeId = calculatedBlock.active_index;

  delete calculatedBlock['totalPrice'];
  delete calculatedBlock.active_index;

  const optionData = blockData.values.options[activeId];

  const price = calculatedBlock[activeId].price;
  let optionName = translate(optionData.label, language);
  if (calculatedBlock[activeId].hasOwnProperty('width')) {
    const { width } = calculatedBlock[activeId];
    const { height } = calculatedBlock[activeId];
    optionName += ` ${width} x ${height}`;
  }

  resultObject[optionName] = price;

  return resultObject;
}
export function foundationPDFObj (blockData, calculatedBlock, language) {
  const resultObject = {};

  Object.keys(calculatedBlock).forEach((id) => {
    if (calculatedBlock[id].checked) {

      const optionData = getOptionById(blockData, id);

      const price = calculatedBlock[id].price;
      let optionName = translate(optionData.label, language);
      if (calculatedBlock[id].hasOwnProperty('value')) {
        const { value } = calculatedBlock[id];
        if (id === '0') {
          const selectLabel = optionData.selections[value].label;
          optionName += `: ${translate(selectLabel, language)}`;
        }
      }
      resultObject[optionName] = price;
    }
  });

  return resultObject;
}