import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Message } from 'semantic-ui-react';
import TranslatedText from '../../01_atoms/TranslatedText';
import './_styles.scss';
import { translate } from '../../../utils/translations';
import getData from "../../../utils/getData";
import {getHousePrice} from "../../../utils/getHousePrice";
import getCalculatedBlock from "../../../utils/getCalculatedBlock";
import getPDFObject from "../../../utils/getPDFObject";
import getTotalPriceObj from "../../../utils/getTotalPriceObj";
import divideNumber from "../../../utils/divideNumber";
import getCurrencySign from "../../../utils/getCurrencySign";
import {getMailTextObj} from "../../../utils/getMailTextObj";
import getHouseInfoList from "../../../utils/getHouseInfoList";

class SendEmailForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Email: '',
      Name: '',
      loading: false,
      success: false,
      error: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.inputHandleChange = this.inputHandleChange.bind(this);
  }

  inputHandleChange(event) {
    const { name } = event.target;
    const { value } = event.target;
    const state = {};
    state[name] = value;

    this.setState(state);
  }

  handleFormSubmit = async (event) => {
    const data = getData();
    const { store } = this.props;
    const { blocks, language, houseId, country } = store;
    const countryName = data.configuration.countries[country].name;
    const houseData = data.houses[houseId];
    const offerType = data.configuration['offer_type'];

    const userName = this.state.Name;
    const userEmail = this.state.Email;

    const pdfObj = {
      offer_type: translate(offerType, language),
      house_configuration: houseData,
      headers_configuration: {
        house: {},
        table_body: {},
        table_footer: {},
        user_data: {},
      },
      user_data: {
        name: userName,
        email: userEmail,
      },
      message: '',
      options: {},
      default_house_info: {}
    };

    // Offer message
    const date = new Date();
    let hours = date.getHours();
    if (hours < 10) {
      hours = '0' + hours;
    }

    let minutes = date.getMinutes();
    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    let day = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    const year = date.getFullYear();

    const messageText = 'The price is not an offer and valid for 5 days.';

    pdfObj.message = `${hours}:${minutes}, ${day}.${month}.${year} ${translate(messageText, language)}`;

    // Add base price to table
    const house = translate('House', language);
    const basePrice = translate('Base price', language);
    let housePrice = getHousePrice(houseId, country);
    housePrice = `${getCurrencySign()} ${divideNumber(housePrice)}`;
    pdfObj.options[house] = {[basePrice]: housePrice};

    // Options procession
    Object.keys(blocks).forEach((blockId) => {
      const blockData = data.blocks.find((block) => (
        block.machine_name === blockId
      ));
      const blockValue = blocks[blockId];

      const calculatedBlock = getCalculatedBlock(blockData, blockValue, store);
      const blockPDFObject = getPDFObject(blockData, calculatedBlock, language);

      // Process price values
      Object.keys(blockPDFObject).forEach(option => {
        let price = blockPDFObject[option].toString();
        switch (price) {
          case 'base': {
            price = translate('Basic option', language);
            break;
          }

          case '0':
          case 'absent': {
            delete blockPDFObject[option];
            return
          }

          default: {
            if (!isNaN(Number(price))) {
              price = `${getCurrencySign()} ${divideNumber(price)}`;
            }
          }
        }
        blockPDFObject[option] = price;
      });

      // If block has active options
      if (Object.keys(blockPDFObject).length !== 0) {
        const blockName = translate(blockData.title, language);
        pdfObj.options[blockName] = blockPDFObject;
      }
    });

    // User data headers
    const { name } = pdfObj.user_data;
    const { email } = pdfObj.user_data;
    const customer = translate('Customer', language);
    const customerMail = '';

    pdfObj.headers_configuration.user_data = {};
    const header = pdfObj.headers_configuration.user_data;
    header[customer] = name;
    header[customerMail] = email;

    // House headers
    const buildArea = translate('Building area', language);
    const usefulArea = translate('Useful area', language);
    const verandaArea = translate('Veranda area', language);

    const houseHeadersCount = 3;
    for (let headerNum = 0; headerNum < houseHeadersCount; headerNum++) {
      pdfObj.headers_configuration.house[headerNum] = {};
      const header = pdfObj.headers_configuration.house[headerNum];
      switch (headerNum) {
        case 0:
          header[buildArea] = houseData.area;
          break;

        case 1:
          header[usefulArea] = houseData.useful_area;
          break;

        case 2:
          header[verandaArea] = houseData.veranda_area;
          break;
      }
    }

    // Table body headers
    const optionName = translate('Option name', language);
    const price = translate('Price', language);

    const tableHeadersCount = 2;
    for (let headerNum = 0; headerNum < tableHeadersCount; headerNum++) {
      pdfObj.headers_configuration.table_body[headerNum] = {};
      switch (headerNum) {
        case 0:
          pdfObj.headers_configuration.table_body[headerNum] = optionName;
          break;

        case 1:
          pdfObj.headers_configuration.table_body[headerNum] = price;
          break;
      }
    }

    // Total price calculation
    let { totalPrice, vatPrice, totalPriceWithVAT } = getTotalPriceObj(store);
    totalPrice = `${getCurrencySign()} ${divideNumber(totalPrice)}`;
    vatPrice = `${getCurrencySign()} ${divideNumber(vatPrice)}`;
    totalPriceWithVAT = `${getCurrencySign()} ${divideNumber(totalPriceWithVAT)}`;

    // Table footer headers
    const costWithoutVAT = translate('Cost without VAT', language);
    const VAT = translate('VAT', language);
    const totalWithVAT = translate('Total with VAT', language);

    const footerHeadersCount = 3;
    for (let headerNum = 0; headerNum < footerHeadersCount; headerNum++) {
      pdfObj.headers_configuration.table_footer[headerNum] = {};
      const header = pdfObj.headers_configuration.table_footer[headerNum];
      switch (headerNum) {
        case 0:
          header[costWithoutVAT] = totalPrice;
          break;

        case 1:
          header[VAT] = vatPrice;
          break;

        case 2:
          header[totalWithVAT] = totalPriceWithVAT;
          break;
      }
    }

    this.setState({
      loading: true,
    });

    //  Offer file name
    const offerName = `vithaus-offer-${offerType.toLowerCase()}-${day}-${month}-${year}-${hours}-${minutes}`;

    //  Default house info
    pdfObj.default_house_info = getHouseInfoList(data, store);

    //  Mail
    const mail = getMailTextObj(country, offerType, language, userName, userEmail);

    const formData = new FormData();
    formData.append('JSON', JSON.stringify(pdfObj));
    formData.append('file_name', offerName);
    formData.append('mail_subject', mail.subject);
    formData.append('mail_body', mail.body);
    formData.append('country', translate(countryName, language));
    formData.append('countryKey', country);


    //  Test backend
    // let backendURL = 'https://offer-calculator-by.skit.by/';

    let backendURL = 'https://offer-calculator.skit.by/';

    const localHost = 'offer.vithaus.local';

    if (typeof window !== 'undefined') {
      if (window.location.host === localHost) {
        backendURL = 'http://backend.vithaus.local/';
      }
    }

    const backendRequest = await fetch(backendURL, {
      method: 'POST',
      body: formData,
    });

    this.setState({
      loading: false,
      success: backendRequest !== false,
      error: backendRequest === false,
    });

    event.persist();
  };

  render() {
    const { language } = this.props.store;
    return (
      <Form onSubmit={this.handleFormSubmit} loading={this.state.loading} error={this.state.error} success={this.state.success}>
        <Message
          success
          content={translate('Offer has been successfully sent to your email address', language)}
        />
        <Message
          error
          content={translate('There is a problem sending your offer. Please contact site administrator', language)}
        />
        <div className="form_title">{translate('Get offer to email', language).toUpperCase()}</div>
        <Form.Field id="form_field">
          <input
            required
            name="Name"
            onChange={this.inputHandleChange}
            value={this.state.Name}
            placeholder={translate('Name', language)}
          />
        </Form.Field>

        <Form.Field id="form_field">
          <input
            required
            type="email"
            name="Email"
            onChange={this.inputHandleChange}
            value={this.state.Email}
            placeholder={translate('E-Mail', language)}
          />
        </Form.Field>
        <Button id="button" type="submit" fluid><TranslatedText text="Send" /></Button>
      </Form>
    );
  }
}

const mapStateToProps = (store) => ({
  store,
});

export default connect(mapStateToProps)(SendEmailForm);
