import getData from "./getData";
import getCalculatedBlock from "./getCalculatedBlock";
import {getHousePrice} from "./getHousePrice";

export default function (store) {
  const { houseId, blocks, country } = store;
  const data = getData();

  const totalBlocksPrice = Object.keys(blocks).reduce((totalPrice, machine_name) => {
    const currentBlock = blocks[machine_name];
    const blockData = data.blocks.find((block) => block.machine_name === machine_name);

    const calculatedBlock = getCalculatedBlock(blockData, currentBlock, store);

    if (!calculatedBlock.hasOwnProperty('totalPrice')) {
      throw new Error(`Calculated block ${machine_name} has not a totalPrice field`);
    }

    const blockPrice = Number(calculatedBlock.totalPrice);

    return totalPrice + blockPrice;
  }, 0);

  const vatPercentage = data.configuration['VAT'][country];
  if (typeof vatPercentage === "undefined") {
    throw new Error(`Country "${country}" does not have VAT`);
  }
  const housePrice = Number(getHousePrice(houseId, country));

  const totalPrice = housePrice + totalBlocksPrice;
  const vatPrice = (totalPrice * vatPercentage) / 100;
  const totalPriceWithVAT = vatPrice + totalPrice;

  return {
    totalPrice: totalPrice,
    vatPrice: vatPrice,
    totalPriceWithVAT: totalPriceWithVAT,
  }
}

